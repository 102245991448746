if (typeof _) == "undefined"
  alert('underscoreが読み込まれていません。')

class Efabric
  photo_id: ""
  canvas_height:400
  canvas_width: 300
  obj: null
  canvas: null
  waitingmode: ""
  pointhistory: []
  textselected: 0
  crossselected: 0
  partnextnum: "1"
  part_id: ""
  selecting: false
  selectedobject: []
  objectcolor: '#ff0000'
  historyUndo: []
  historyNextState: null
  historyProcessing: false
  lastselected: null

  textbox_baseoption: {
    width: 50
    fontSize: 10
    textAlign: 'center'
    selectable: true
    hasBorders: true
    hasControls: false
    lockScalingX: true
    lockScalingY: true
    lockRotation: true
    borderColor: '#000'
    extendtype: 'singlearrow-text'
  }

  baseoption: {
    hasControls: true
    cornerStyle: 'circle'
    cornerSize: 25
    transeparentCorners: true
    cornerColor: 'rgba(178, 204, 255, 0.1)'
    cornerStrokeColor: 'rgba(178, 204, 255, 0.8)'
    borderColor: 'rgba(178, 204, 255, 0.8)'
    hasRotatingPoint: true
    fontFamily: "Arial, sans-serif"
  }

  line_baseoption: {
    selectable: false
    padding: 0
    hasBorders: false
    hasControls: false
    originX: 'center'
    originY: 'center'
    lockScalingX: true
    lockScalingY: true
    perPixelTargetFind: true
  }

  arrow_baseoption: {
    #stroke: 'black'
    #//stroke: 'blue'
    #strokeWidth: 1
    originX: 'center'
    originY: 'center'
    selectable: true
    hasBorders: false
    hasControls: false
    lockScalingX: false
    lockScalingY: false
    lockRotation: true
    pointType: 'arrow_start'
    angle: -45
    perPixelTargetFind: true
  }

  circle_baseoption: {
    radius: 10
    stroke: 'transparent'
    #stroke: 'rgba(255, 0, 0, 0.5)'
    fill: 'transparent'
    #fill: 'rgba(255, 0, 0, 0.1)'
    strokeWidth: 1
    originX: 'center'
    originY: 'center'
    hasBorders: false
    hasControls: false
    lockScalingX: true
    lockScalingY: true
    lockRotation: true
  }

  init: (ele, data = null, extra = null, photo_id = null) ->
    console.log @canvas

    # if (@canvas != null)
    #   @canvas.dispose() #前のcanvasが残っていると問題が発生するのでdispose
    #eFabric.init('c', data.canvas, data.canvas.extra, photo_id)
    @canvas = window.__canvas = new (fabric.Canvas)(ele,
      isDrawingMode: false
      selection: false
      hoverCursor: 'default'
      backgroundColor: 'rgb(255, 255, 255)'
      width: @canvas_width
      height: @canvas_height
      targetFindTolerance: 5
    )
    ctx = @canvas.getContext('2d')
    ctx.fillStyle = 'rgb(255, 255, 255)'
    ctx.fillRect(0, 0, @canvas_width, @canvas_height)
    if photo_id?
      @canvas.photo_id = photo_id
    canvas = @canvas
    @canvas.on({
      'selection:created': @objectselected
      'selection:updated': @objectselected
      'before:selection:cleared': @objectselectclear
    #   #'touch:gesture': touch_gesture
      'mouse:down': @mousedown
      # 'object:moved': (e) ->
      #   console.log "moved" if debugmode? and debugmode == true and console?
      #   e.target.opacity = 0.5
      # 'object:modified': (e) ->
      #   e.target.opacity = 1
      #   @getpoint

    })
    @canvas.eFabric = @
    if data? and data.objects? and data.objects.length >= 1
      @restore(data, extra)
    else
      if (document.getElementById('image1') != null)
        @addBaseImage()

  # historyInit: ->
  #   @historyUndo = []
  #   @historyNextState = @historyNext()
  #   @canvas.on({
  #     "object:added": @historySaveAction
  #     "object:removed": @historySaveAction
  #     "object:modified": @historySaveAction
  #   })


  # historyNext: ->
  #   #JSON.stringify(@canvas.toDatalessJSON(@canvas.extraProps))
  #   @stringifyCanvas()

  # historySaveAction: (e) ->
  #   console.log e if debugmode? and debugmode == true and console?
  #   if (@.eFabric.historyProcessing)
  #     return
  #   json = @.eFabric.historyNextState
  #   # console.log json if debugmode? and debugmode == true and console?
  #   @.eFabric.historyUndo.push(json)
  #   @.eFabric.historyNextState = @.eFabric.historyNext()

  # undo: ->
  #   @historyProcessing = true
  #   historyjson = @historyUndo.pop()
  #   # console.log @historyUndo.length if debugmode? and debugmode == true and console?
  #   # console.log historyjson if debugmode? and debugmode == true and console?
  #   if (historyjson)
  #     history = JSON.parse(historyjson)
  #     #@canvas.loadFromJSON(history).renderAll()
  #     @restore(history, history.extra)
  #     @canvas.renderAll()
  #   @historyProcessing = false
  undo: ->
    @canvas.undo()

  destroy: ->
    ele = document.getElementById('canvas')
    if ele != null
      #@canvas.clear()
      @canvas.dispose()
      if ele.parentNode != null
        ele.parentNode.removeChild(ele)
    ele = document.getElementById('my-image')
    if ele != null
      if ele.parentNode != null
        ele.parentNode.removeChild(ele)

  mainimageoption: {
    selectable: false
    left: 0
    top: 0
    objectid: 'mainimage'
    preserveObjectStacking: false
    hasBorders: false
    hasControls: false
  }
  addBaseImage: ->
    console.log '#image1をロードします。'
    img = new fabric.Image(document.getElementById('image1'), @mainimageoption)
    @canvas.add(img)
    if img.width >= img.height
      img.scaleToHeight @canvas_height
      #img.left = -1 * (img.width * img.scaleX - (@canvas.width)) / 2
    else
      img.scaleToWidth @canvas_width
      #img.top = -1 * (img.height * img.scaleY - (@canvas.height)) / 2
    @canvas.renderAll()

  objectselectclear: (e) ->
    console.log 'オブジェクトの選択が解除されます。'
    console.log @.eFabric.lastselected
    obj = @.eFabric.lastselected
    @.eFabric._objectselectclear(obj)

  _objectselectclear: (obj) ->
    console.log obj
    if obj[0]?
      target = obj[0]
    else
      target = obj
    if target.canvas?
      target.canvas.eFabric.bringToFrontText()
    if (!target.customType || (target.customType != "arrow"))
      target.set({
        'opacity': '1.0'
      })
    if (target.arrow)
      target.arrow.set({
        'opacity': '1.0'
      })
    if (target.arrow2)
      target.arrow2.set({
        'opacity': '1.0'
      })
    if (target.line)
      target.line.set({
        'opacity': '1.0'
      })
      if (target.line.arrow)
        target.line.arrow.set({
          'opacity': '1.0'
        })
      if (target.line.arrow2)
        target.line.arrow2.set({
          'opacity': '1.0'
        })

  objectselected: (e) ->
    console.log 'オブジェクトが選択されました。'
    if (@.eFabric.lastselected)
      @.eFabric._objectselectclear(@.eFabric.lastselected)
    obj = @.getActiveObjects()
    @.eFabric.lastselected = obj
    console.log obj

    if (!obj[0].customType || (obj[0].customType != "arrow"))
      obj[0].set({
        'opacity': '0.4'
      })
    # 選択したパーツ全体をまとめて色変える
    # if (obj[0].circle)
    #   obj[0].circle.set({
    #     'opacity': '0.4'
    #   })
    # if (obj[0].circle0)
    #   obj[0].circle0.set({
    #     'opacity': '0.4'
    #   })
    if (obj[0].arrow)
      obj[0].arrow.set({
        'opacity': '0.4'
      })
    if (obj[0].arrow2)
      obj[0].arrow2.set({
        'opacity': '0.4'
      })
    # if (obj[0].pointarrow)
    #   obj[0].pointarrow.set({
    #     'opacity': '0.4'
    #   })
    if (obj[0].line)
      obj[0].line.set({
        'opacity': '0.4'
      })
      # if (obj[0].line.circle)
      #   obj[0].line.circle.set({
      #     'opacity': '0.4'
      #   })
      # if (obj[0].line.circle0)
      #   obj[0].line.circle0.set({
      #     'opacity': '0.4'
      #   })
      if (obj[0].line.arrow)
        obj[0].line.arrow.set({
          'opacity': '0.4'
        })
      if (obj[0].line.arrow2)
        obj[0].line.arrow2.set({
          'opacity': '0.4'
        })
      # if (obj[0].line.pointarrow)
      #   obj[0].line.pointarrow.set({
      #     'opacity': '0.4'
      #   })

    if obj[0]._objects != null
      console.log 'これはグループオブジェクトです。'
    console.log obj[0].extendtype
    if (obj[0].extendtype == "singlearrow-text" || obj[0].extendtype == "doublearrow-text")
      console.log(obj[0].text)
      # if (obj[0].text?)
      #   currentText = obj[0].text
      # else
      #   currentText = obj[0].textbox.text
      # newtext = prompt('入力してください。', currentText)
      # if (newtext != null)
      #   console.log(newtext)
      #   #テキストを書き換える
      #   obj[0].text = newtext
      #   obj[0].line.ontext = newtext
      #   @.renderAll()
      # #選択状態を解除し、
      # @.discardActiveObject()

  waiting: (waitingmode, options) ->
    @canvas.waitingmode = waitingmode
    @canvas.waitingoptions = options
    @canvas.pointhistory = []

  openprompt: (msg, txt) -> #promptのfunction。これを上書きする
    return new Promise (resolve, reject) ->
      result = prompt(msg, txt)
      console.log(result)
      resolve result

  openprompt_addcross: (msg, txt) ->
    return new Promise (resolve, reject) ->
      result = prompt(msg, txt)
      console.log(result)
      resolve result

  openprompt_text: (msg, txt) ->
    return new Promise (resolve, reject) ->
      result = prompt(msg, txt)
      console.log(result)
      resolve result

  before_addcross: ->
    return new Promise (resolve, reject) ->
      resolve true

  # openprompt_crosstext: ->
  #   @circletext()

  openprompt_circletext: ->
    return new Promise (resolve, reject) ->
      result = confirm('破損箇所登録に進みます')
      console.log(result)
      resolve result

  onselectchange: (selecting, type, extendtype) ->
    console.log selecting
    #console.log type
    #console.log extendtype

  mousedown: (e) ->
    #console.log "mousedown"
    # if (e.target.objectid != "mainimage")
    #   e.target.opacity = 0.5
    #   @.renderAll()
    # canvas は@
    # console.log @.getPointer(e.e) if debugmode? and debugmode == true and console?
    # console.log @.waitingmode if debugmode? and debugmode == true and console?
    # console.log @.eFabric if debugmode? and debugmode == true and console?
    if !@.waitingmode
      obj = @.eFabric.canvas.getActiveObject()
      console.log obj
      if obj?
        @selecting = true
        @.eFabric.onselectchange(@selecting, obj.type, obj.extendtype)
      else
        @selecting = false
        @.eFabric.onselectchange(@selecting)
      #console.log @selecting

      Efab = @.eFabric
      # if obj? and (obj.extendtype? and (obj.extendtype == "singlearrow-text" || obj.extendtype == "doublearrow-text" || obj.extendtype == "line-text" || obj.extendtype == "measureline-text") || (obj.type == "textbox"))
      if obj? and
      (obj.extendtype? and
       _.contains(['singlearrow-text', 'doublearrow-text', 'line-text', 'measureline-text'], obj.extendtype) ||
      _.contains(['textbox'], obj.type))
        obj.lockMovementX = false
        obj.lockMovementY = false
        console.log "obj.lockMovementX", obj.lockMovementX if debugmode? and debugmode == true and console?
        console.log "obj.lockMovementY", obj.lockMovementY if debugmode? and debugmode == true and console?
        console.log "obj.extendtype", obj.extendtype if debugmode? and debugmode == true and console?
        console.log "obj.type", obj.type if debugmode? and debugmode == true and console?
        #@.eFabric.textselected += 1
        if @.eFabric.textselected == 0
          console.log "一回目の選択です。", obj if debugmode? and debugmode == true and console?
          @selectedobject = obj
          @.eFabric.selectedlast = new Date().getTime()
          console.log @.eFabric.selectedlast if debugmode? and debugmode == true and console?
        if @selectedobject == obj
          @.eFabric.textselected += 1
        else
          @.eFabric.textselected = 0

        limit = 1000
        if @.eFabric.textselected >= 2
          console.log (@.eFabric.selectedlast) if debugmode? and debugmode == true and console?
          console.log (new Date().getTime()) if debugmode? and debugmode == true and console?

        if @.eFabric.textselected >= 2
          if ((new Date().getTime() - @.eFabric.selectedlast) <= limit)
            console.log limit + "ms以内にテキストが2回選択されました。" if debugmode? and debugmode == true and console?
            if (obj.text?)
              currentText = obj.text
            else
              currentText = obj.textbox.text
            if obj.type == "textbox"
              newtext = await @.eFabric.openprompt_text('入力してください。', currentText).then (newtext) ->
                if (newtext != null and newtext != "")
                  #テキストを書き換える
                  console.log(obj)
                  obj.text = newtext
                  if obj.line? and  obj.line.ontext?
                    obj.line.ontext = newtext
                  obj.canvas.renderAll()
                #選択状態を解除
                # @.eFabric.canvas.setActiveObject(obj)
                obj.lockMovementX = true # ドラッグ状態になったまま解除されないのでこの2行で対処
                obj.lockMovementY = true
                obj.canvas.discardActiveObject()
                obj.canvas.setActiveObject(obj) #再度選択状態
                obj.canvas.renderAll() #
                obj.textselected = 0
            else
              newtext = await @.eFabric.openprompt('入力してください。', currentText).then (newtext) ->
                if (newtext != null and newtext != "")
                  #テキストを書き換える
                  console.log(obj)
                  obj.text = newtext
                  if obj.line? and  obj.line.ontext?
                    obj.line.ontext = newtext
                  obj.canvas.renderAll()
                #選択状態を解除
                # @.eFabric.canvas.setActiveObject(obj)
                obj.lockMovementX = true # ドラッグ状態になったまま解除されないのでこの2行で対処
                obj.lockMovementY = true
                obj.canvas.discardActiveObject()
                obj.canvas.setActiveObject(obj) #再度選択状態
                obj.canvas.renderAll() #
                obj.textselected = 0
          else
            console.log "遅いです。reset" if debugmode? and debugmode == true and console?
            @.eFabric.textselected = 0
            #@.eFabric.canvas.discardActiveObject().renderAll() #

        else if @.eFabric.textselected == 1
          console.log "２回目待ちです。" if debugmode? and debugmode == true and console?
        else
          obj.canvas.discardActiveObject().renderAll() #
          obj.textselected = 0
          @.eFabric.textselected = 0

      else if obj? and obj.extendtype? and (obj.extendtype == "cross")
        if @.eFabric.crossselected == 0
          @selectedobject = obj
        if @selectedobject == obj
          @.eFabric.crossselected += 1
        else
          @.eFabric.crossselected = 0
        console.log @.eFabric.crossselected
        if @.eFabric.crossselected >= 2
          console.log "Crossが2回選択されました。" if debugmode? and debugmode == true and console?
          if (obj._objects[3].text?)
            currentText = obj._objects[3].text
          else
            currentText = ""
          p_eFabric = @.eFabric
          newtext = await @.eFabric.openprompt_addcross('入力してください。', currentText).then (newtext) ->
            if (newtext != null and newtext != "")

              obj.toActiveSelection() #解除
              #選択されているものをループ
              selected = p_eFabric.canvas.getActiveObject()
              console.log selected if debugmode? and debugmode == true and console?

              #テキストを書き換える
              #console.log(obj)
              selected._objects[3].text = newtext
              p_eFabric.canvas.getActiveObject().toGroup().set({extendtype: 'cross', hasControls: false})

              #obj.canvas.renderAll()
            #選択状態を解除
            # @.eFabric.canvas.setActiveObject(obj)
            obj.lockMovementX = true # ドラッグ状態になったまま解除されないのでこの2行で対処
            obj.lockMovementY = true
            p_eFabric.canvas.discardActiveObject().renderAll()
          obj.crossselected = 0
        else if @.eFabric.crossselected >= 2
          obj.crossselected = 0

      else
        console.log "reset"
        @.eFabric.textselected = 0
        @.eFabric.crossselected = 0
    pointer = null
    if (
      _.contains([
        'add-arrow'
        'add-double-arrow'
        'add-line'
        'add-boldline'
        'add-middleboldline'
        'add-middle2boldline'
        'add-dashline'
        'add-middlebolddashline'
        'add-middle2bolddashline'
        'add-bolddashline'
        'add-measure-line'
        'add-boldarrow'
        'add-middleboldarrow'
        'add-bolddoublearrow'
        'add-middlebolddoublearrow'
        'add-middle2boldarrow'
        'add-middle2bolddoublearrow'
      ], @.waitingmode)
    )
      pointer = @.getPointer(e.e)
      @.pointhistory.push({x: pointer.x, y:pointer.y})
      if (@.pointhistory.length == 2)
        #console.log(@.pointhistory)
        switch @.waitingmode
          when "add-arrow" #極細矢印
            @.eFabric.addArrow([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()

          when "add-boldarrow" #太矢印
            @.eFabric.addBoldArrow([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-middleboldarrow" #中太矢印
            @.eFabric.addMiddleBoldArrow([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-middle2boldarrow" #中太2矢印
            @.eFabric.addMiddle2BoldArrow([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-double-arrow" #極細両矢印
            @.eFabric.addDoubleArrow([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-bolddoublearrow" #太両矢印
            @.eFabric.addBoldDoubleArrow([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-middlebolddoublearrow" #中太両矢印
            @.eFabric.addMiddleBoldDoubleArrow([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-middle2bolddoublearrow" #中太2両矢印
            @.eFabric.addMiddle2BoldDoubleArrow([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-line"
            @.eFabric.addLine([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-boldline"
            @.eFabric.addBoldLine([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-middleboldline"
            @.eFabric.addMiddleBoldLine([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-middle2boldline"
            @.eFabric.addMiddle2BoldLine([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-dashline"
            @.eFabric.addDashLine([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor, @.waitingoptions)
            @.eFabric.finishwaitingmode()
          when "add-middle2bolddashline"
            @.eFabric.addMiddle2BoldDashLine([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-middlebolddashline"
            @.eFabric.addMiddleBoldDashLine([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-bolddashline"
            @.eFabric.addBoldDashLine([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
            @.eFabric.finishwaitingmode()
          when "add-measure-line"
            isnew = true
            @.eFabric.addMeasureLine([@.pointhistory[1].x, @.pointhistory[1].y, @.pointhistory[0].x, @.pointhistory[0].y], "0", @.eFabric.objectcolor, isnew)
            @.eFabric.finishwaitingmode()
        @.waitingmode = ""
        @.pointhistory = []

    else if (@.waitingmode == "add-circle-text")
      pointer = @.getPointer(e.e)
      @.pointhistory.push({x: pointer.x, y:pointer.y})
      if (@.pointhistory.length == 1)
        console.log(@.pointhistory)
        @.eFabric.addCircleText([@.pointhistory[0].x, @.pointhistory[0].y], "1")
        @.waitingmode = ""
        @.pointhistory = []
        @.hoverCursor = 'default'
        await @.eFabric.openprompt_circletext().then ->
          console.log "進めます" if debugmode? and debugmode == true and console?
    else if (@.waitingmode == "add-ellipse")
      pointer = @.getPointer(e.e)
      @.pointhistory.push({x: pointer.x, y:pointer.y})
      if (@.pointhistory.length == 1)
        console.log(@.pointhistory)
        @.eFabric.addEllipse([@.pointhistory[0].x, @.pointhistory[0].y], "", @.eFabric.objectcolor)
        @.eFabric.finishwaitingmode()
        @.waitingmode = ""
        @.pointhistory = []
        @.hoverCursor = 'default'
    else if (@.waitingmode == "add-text")
      pointer = @.getPointer(e.e)
      @.pointhistory.push({x: pointer.x, y:pointer.y})
      if (@.pointhistory.length == 1)
        console.log(@.pointhistory)
        @.eFabric.addText([@.pointhistory[0].x, @.pointhistory[0].y], "テキスト", @.eFabric.objectcolor)
        @.eFabric.finishwaitingmode()
        @.waitingmode = ""
        @.pointhistory = []
        @.hoverCursor = 'default'
    else if (@.waitingmode == "add-cross")
      pointer = @.getPointer(e.e)
      @.pointhistory.push({x: pointer.x, y:pointer.y})
      if (@.pointhistory.length == 1)
        console.log(@.pointhistory)
        console.log(@part_id)
        @.eFabric.addCross([@.pointhistory[0].x, @.pointhistory[0].y], @partnextnum, @.eFabric.objectcolor)
        @.eFabric.finishwaitingmode()
        @.waitingmode = ""
        @.pointhistory = []
        @.hoverCursor = 'default'
        await @.eFabric.before_addcross().then ->
          console.log "進めます" if debugmode? and debugmode == true and console?

  finishwaitingmode: ->

  addCompass: ->
    circle = new fabric.Circle({
      left: 30
      top: 30
      radius: 14
      stroke: 'rgba(233, 234, 237, 1)'
      #stroke: 'red'
      strokeWidth: 1
      originX: 'center'
      originY: 'center'
      hasBorders: false
      hasControls: true
      lockScalingX: true
      lockScalingY: true
      lockRotation: false
      fill: 'rgba(233, 234, 237, 0.3)'
      extendtype: 'circletext'
    })
    tri1 = new fabric.Triangle({
      left: 30
      top: 25
      originX: 'center'
      originY: 'center'
      stroke: 'transparent'
      strokeWidth: 0
      hasBorders: false
      hasControls: false
      lockScalingX: true
      lockScalingY: true
      lockRotation: true
      pointType: 'arrow_start'
      angle: 0
      width: 10
      height: 10
      fill: 'red'
    })
    tri2 = new fabric.Triangle({
      left: 30
      top: 35
      originX: 'center'
      originY: 'center'
      stroke: 'transparent'
      strokeWidth: 0
      hasBorders: false
      hasControls: false
      lockScalingX: true
      lockScalingY: true
      lockRotation: true
      pointType: 'arrow_start'
      angle: 180
      width: 10
      height: 10
      fill: '#9a9a9a'
    })
    compass = new fabric.Group([circle, tri1, tri2], {
      hasControls: true
      hasBorders: false
      cornerStyle: 'circle'
      cornerSize: 25
      cornerColor: 'rgba(178, 204, 255, 0.1)'
      cornerStrokeColor: 'rgba(178, 204, 255, 0.8)'
      borderColor: 'rgba(178, 204, 255, 0.8)'
      hasRotatingPoint: true
    })
    compass.controls.mtr.y = 0.5 #rotateボタンの位置調整。寄せる
    compass.setControlsVisibility({
      tl: false
      tr: false
      br: true
      bl: false
      ml: false
      mt: false
      mr: false
      mb: false
      mtr: true
    })
    eFabric.canvas.add(compass)

  # addCompass: (top = null, left = null, width = null, height = null, angle = 0) ->
  #   if width == null
  #     mode = 'new'
  #     # 指定ない場合のみ
  #     width = 20.75
  #     height = 62.75
  #   if mode == 'new'
  #     # if top == null
  #     #   top = (@canvas_height - height - 10)
  #     # if left == null
  #     #   left = (@canvas_width - width - 30)
  #     top = 10
  #     left = 10
  #   compass = new (fabric.Image)(document.getElementById('compass'),
  #     selectable: true
  #     left: left
  #     top: top
  #     objectid: 'compassimage'
  #     hasBorders: true
  #     hasControls: true
  #     cornerSize: 20
  #     cornerColor: '#FF0000'
  #     cornerStrokeColor: '#000000'
  #     cornerStyle: 'circle'
  #     padding: 0
  #     scaleX: 0.25
  #     scaleY: 0.25
  #     transparentCorners: false
  #     hasRotatingPoint: true
  #     objectCaching: false
  #     extendtype: 'compass'
  #     angle: angle
  #   )
  #   # compass.controls.delete = new fabric.Control({
  #   #   x: -1.1
  #   #   y: -1.0
  #   #   offsetY: 70
  #   #   cursorStyle: 'pointer'
  #   #   mouseUpHandler: @deleteObject
  #   #   render: @renderDeleteIcon
  #   #   cornerSize: 20
  #   # })
  #   compass.controls.mtr.y = -0.05 #rotateボタンの位置調整。寄せる
  #   #compass.controls.mtr.render = renderRotateIcon #rotateアイコンの表示
  #   compass.setControlsVisibility({
  #     tl: false
  #     tr: false
  #     br: false
  #     bl: false
  #     ml: false
  #     mt: false
  #     mr: false
  #     mb: false
  #     mtr: true
  #   })
  #   @canvas.add(compass)

  addDoubleArrow:(pos = [0, 0, 100, 100], text = "", color = "red", bold = 'normal') ->
    #console.log pos if debugmode? and debugmode == true and console?
    if (bold == "Bold")
      strokeWidth = 30
      extendtype = 'bolddoublearrow'
      width = 60
      height = 20
    else if (bold == "MiddleBold")
      strokeWidth = 18
      extendtype = 'middlebolddoublearrow'
      width = 40
      height = 15
    else if (bold == "Middle2Bold")
      strokeWidth = 9
      extendtype = 'middle2bolddoublearrow'
      width = 20
      height = 10
    else
      strokeWidth = 1
      extendtype = 'doublearrow'
      width = 8
      height = 8
    line = new fabric.Line(pos, @merge(@line_baseoption, {
      stroke: color
      strokeWidth: strokeWidth
      extendtype: extendtype
      ontext: text
    }))
    centerX = (line.x1 + line.x2) / 2
    centerY = (line.y1 + line.y2) / 2
    deltaX = line.left - centerX
    deltaY = line.top - centerY
    arrow = new fabric.Triangle(@merge(@arrow_baseoption, {
      left: line.get('x1') + deltaX,
      top: line.get('y1') + deltaY,
      #stroke: 'transparent',
      pointType: 'arrow_start',
      angle: -45,
      width: width,
      height: height,
      fill: color,
      extendtype: extendtype,
    }))
    arrow.line = line
    arrow2 = new fabric.Triangle(@merge(@arrow_baseoption, {
      left: line.get('x2') + deltaX,
      top: line.get('y2') + deltaY,
      pointType: 'arrow_end',
      angle: 135,
      width: width,
      height: height,
      fill: color,
      extendtype: extendtype,
    }))
    arrow2.line = line
    if (text != "")
      textbox = new fabric.Text(text, @merge(@textbox_baseoption, {
        top: centerX,
        left: centerY,
        backgroundColor: color,
        extendtype: 'doublearrow-text'
      }))

      textbox.line = line
      textbox.set({fill: '#fff'})

    if (text != "")
      line.customType = arrow.customType = arrow2.customType = textbox.customType = 'arrowarrow'
      line.arrow2 = arrow.arrow2 = textbox.arrow2 = arrow2
      line.arrow = arrow2.arrow = textbox.arrow = arrow
      line.textbox = arrow.textbox = arrow2.textbox = textbox
    else
      line.customType = arrow.customType = arrow2.customType = 'arrowarrow'
      line.arrow2 = arrow.arrow2 = arrow2
      line.arrow = arrow2.arrow = arrow

    if (text != "")
      @canvas.add(line, arrow, arrow2, textbox)
    else
      @canvas.add(line, arrow, arrow2)

    _moveEnd2 = (obj) ->
      if (obj.pointType == 'arrow_end')
        obj.line.set('x2', obj.get('left'))
        obj.line.set('y2', obj.get('top'))
      else
        obj.line.set('x1', obj.get('left'))
        obj.line.set('y1', obj.get('top'))

      if (text != "")
        obj.line.textbox.set({
          'left': (line.x1 + line.x2) / 2,
          'top': (line.y1 + line.y2) / 2
        }).setCoords()

      obj.line._setWidthHeight()

      x1 = obj.line.get('x1')
      y1 = obj.line.get('y1')
      x2 = obj.line.get('x2')
      y2 = obj.line.get('y2')

      angle = obj.canvas.eFabric.calcArrowAngle(x1, y1, x2, y2)

      if (obj.pointType == 'arrow_end')
        obj.set('angle', angle + 90)
        obj.arrow.set('angle', angle - 90)
      else
        obj.set('angle', angle - 90)
        obj.arrow2.set('angle', angle + 90)
      obj.canvas.eFabric.preventArrow(obj)
      obj.line.setCoords()
      obj.canvas.renderAll()

    _moveLine2 = (line) ->
      oldCenterX = (line.x1 + line.x2) / 2
      oldCenterY = (line.y1 + line.y2) / 2
      deltaX = line.left - oldCenterX
      deltaY = line.top - oldCenterY

      line.arrow.set({
        'left': line.x1 + deltaX,
        'top': line.y1 + deltaY
      }).setCoords()

      line.arrow2.set({
        'left': line.x2 + deltaX,
        'top': line.y2 + deltaY
      }).setCoords()

      if (text != "")
        line.textbox.set({
          'left': (line.x1 + line.x2) / 2,
          'top': (line.y1 + line.y2) / 2
        }).setCoords()

      line.set({
        'x1': line.x1 + deltaX,
        'y1': line.y1 + deltaY,
        'x2': line.x2 + deltaX,
        'y2': line.y2 + deltaY
      })

      line.set({
        'left': (line.x1 + line.x2) / 2,
        'top': (line.y1 + line.y2) / 2
      })
      line.canvas.eFabric.preventLineOut(line)

    _moveEnd2(arrow)
    _moveEnd2(arrow2)
    arrow.on({'moving': ->
      _moveEnd2(arrow)
    })

    arrow2.on({'moving': ->
      _moveEnd2(arrow2)
    })

    line.on({'moving': ->
      _moveLine2(line)
    })

  addBoldDoubleArrow:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    @addDoubleArrow(pos, text, color, "Bold")

  addMiddleBoldDoubleArrow:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    console.log "middleboldarrow" if debugmode? and debugmode == true and console?
    @addDoubleArrow(pos, text, color, "MiddleBold")

  addMiddle2BoldDoubleArrow:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    console.log "middleboldarrow" if debugmode? and debugmode == true and console?
    @addDoubleArrow(pos, text, color, "Middle2Bold")

  hex2rgb: (hex) ->
    if hex.slice(0, 1) == '#'
      hex = hex.slice(1)
    [
      hex.slice(0, 2)
      hex.slice(2, 4)
      hex.slice(4, 6)
    ].map (str) ->
      parseInt(str, 16)


  addMeasureLine:(pos = [0, 0, 100, 100], text = "", color = "#ff0000", isnew = false) ->
    #console.log pos if debugmode? and debugmode == true and console?
    # console.log @hex2rgb('#ff8040')
    # console.log(color)
    if color.slice(0, 1) == "#"
      rgb = @hex2rgb(color)
      #console.log color
      #console.log rgb
      console.log "rgba(#{rgb[0]}, #{rgb[1]}, #{rgb[2]}, 0.5)"
    else
      match = color.match(/([0-9]{1,3}), ([0-9]{1,3}), ([0-9]{1,3}), /)
      #console.log(match)
      rgb = [
        match[1]
        match[2]
        match[3]
      ]
      #console.log rgb
    line = new fabric.Line(pos, {
      stroke: "rgba(#{rgb[0]}, #{rgb[1]}, #{rgb[2]}, 0.5)"
      selectable: true
      #evented: false
      strokeWidth: 1
      padding: 5
      hasBorders: true
      hasControls: true
      originX: 'center'
      originY: 'center'
      lockScalingX: true
      lockScalingY: true
      extendtype: 'measureline'
      ontext: text
      perPixelTargetFind: true
    })
    centerX = (line.x1 + line.x2) / 2
    centerY = (line.y1 + line.y2) / 2
    deltaX = line.left - centerX
    deltaY = line.top - centerY
    arrow = new fabric.Triangle({
      left: line.get('x1') + deltaX,
      top: line.get('y1') + deltaY,
      originX: 'center',
      originY: 'center',
      stroke: 'transparent',
      fill: "rgba(#{rgb[0]}, #{rgb[1]}, #{rgb[2]}, 0.5)"
      strokeWidth: 5,
      hasBorders: false,
      hasControls: false,
      lockScalingX: true,
      lockScalingY: true,
      lockRotation: true,
      pointType: 'arrow_start',
      angle: -45,
      width: 5,
      height: 5,
      extendtype: 'measureline',
    })
    arrow.line = line
    arrow2 = new fabric.Triangle({
      left: line.get('x2') + deltaX,
      top: line.get('y2') + deltaY,
      originX: 'center',
      originY: 'center',
      stroke: 'transparent',
      fill: "rgba(#{rgb[0]}, #{rgb[1]}, #{rgb[2]}, 0.5)",
      strokeWidth: 5,
      hasBorders: false,
      hasControls: false,
      lockScalingX: true,
      lockScalingY: true,
      lockRotation: true,
      pointType: 'arrow_end',
      angle: 135,
      width: 5,
      height: 5,
      extendtype: 'measureline',
    })
    arrow2.line = line
    if (isnew)
      textbox = new fabric.Textbox(text, {
        fontFamily: "Arial, sans-serif"
        width: 15
        left: pos[0] + 10.5
        top: pos[1] - 9
        fontSize: 7
        selectable: true
        hasBorders: true
        hasControls: false
        lockScalingX: true
        lockScalingY: true
        lockRotation: true
        editable: false
        textAlign: 'center'
        backgroundColor: '#ffffff'
      })

      textbox.line = line
      textbox.set({fill: "rgba(#{rgb[0]}, #{rgb[1]}, #{rgb[2]}, 0.8)"})

    line.customType = arrow.customType = arrow2.customType = 'arrowarrow'
    line.arrow2 = arrow.arrow2 = arrow2
    line.arrow = arrow2.arrow = arrow
    # line.customType = arrow.customType = arrow2.customType = textbox.customType = 'arrowarrow'
    # line.arrow2 = arrow.arrow2 = textbox.arrow2 = arrow2
    # line.arrow = arrow2.arrow = textbox.arrow = arrow
    # line.textbox = arrow.textbox = arrow2.textbox = textbox
    line.setControlsVisibility({
      tl: false
      tr: false
      br: false
      bl: false
      ml: false
      mt: false
      mr: false
      mb: false
      mtr: false
    })
    if (isnew)
      console.log "measurelineの新規追加です。" if debugmode? and debugmode == true and console?
      textbox.set({
        'left': (line.x1 + line.x2) / 2,
        'top': (line.y1 + line.y2) / 2
      }).setCoords()

    if isnew
      @canvas.add(line, arrow, arrow2, textbox)
    else
      @canvas.add(line, arrow, arrow2)

    _moveEnd2 = (obj) ->
      if (obj.pointType == 'arrow_end')
        obj.line.set('x2', obj.get('left'))
        obj.line.set('y2', obj.get('top'))
      else
        obj.line.set('x1', obj.get('left'))
        obj.line.set('y1', obj.get('top'))

      # obj.line.textbox.set({
      #   'left': (line.x1 + line.x2) / 2,
      #   'top': (line.y1 + line.y2) / 2
      # }).setCoords()

      obj.line._setWidthHeight()

      x1 = obj.line.get('x1')
      y1 = obj.line.get('y1')
      x2 = obj.line.get('x2')
      y2 = obj.line.get('y2')

      angle = obj.canvas.eFabric.calcArrowAngle(x1, y1, x2, y2)

      if (obj.pointType == 'arrow_end')
        obj.set('angle', angle + 90)
        obj.arrow.set('angle', angle - 90)
      else
        obj.set('angle', angle - 90)
        obj.arrow2.set('angle', angle + 90)
      obj.canvas.eFabric.preventArrow(obj)
      obj.line.setCoords()
      obj.canvas.renderAll()

    _moveLine2 = (line) ->
      oldCenterX = (line.x1 + line.x2) / 2
      oldCenterY = (line.y1 + line.y2) / 2
      deltaX = line.left - oldCenterX
      deltaY = line.top - oldCenterY

      line.arrow.set({
        'left': line.x1 + deltaX,
        'top': line.y1 + deltaY
      }).setCoords()

      line.arrow2.set({
        'left': line.x2 + deltaX,
        'top': line.y2 + deltaY
      }).setCoords()

      # line.textbox.set({
      #   'left': (line.x1 + line.x2) / 2,
      #   'top': (line.y1 + line.y2) / 2
      # }).setCoords()

      line.set({
        'x1': line.x1 + deltaX,
        'y1': line.y1 + deltaY,
        'x2': line.x2 + deltaX,
        'y2': line.y2 + deltaY
      })

      line.set({
        'left': (line.x1 + line.x2) / 2,
        'top': (line.y1 + line.y2) / 2
      })
      line.canvas.eFabric.preventLineOut(line)

    _moveEnd2(arrow)
    _moveEnd2(arrow2)
    arrow.on({'moving': ->
      _moveEnd2(arrow)
    })

    arrow2.on({'moving': ->
      _moveEnd2(arrow2)
    })

    line.on({'moving': ->
      _moveLine2(line)
    })


  addLine:(pos, text, color, bold = "normal", linetype = "normal", width = null) ->
    console.log pos if debugmode? and debugmode == true and console?
    if (bold == "Bold")
      strokeWidth = 30
      extendtype = 'boldline'
      # width = 60
      # height = 20
    else if (bold == "MiddleBold")
      strokeWidth = 18
      extendtype = 'middleboldline'
      # width = 40
      # height = 15
    else if (bold == "Middle2Bold")
      strokeWidth = 9
      extendtype = 'middle2boldline'
      # width = 20
      # height = 10
    else
      strokeWidth = 1
      extendtype = 'line'
      # width = 8
      # height = 8
    lineoption = @merge(@line_baseoption, {
      stroke: color
      #selectable: true
      strokeWidth: strokeWidth
      extendtype: extendtype
      ontext: text
    })
    if (linetype == "dash" and bold == "normal")
      lineoption.strokeDashArray = [5, 5]
      if (width != null)
        lineoption.strokeWidth = width
        lineoption.strokeDashArray = [Math.min(5 + (5 * (width - 1)), 20), 5]
      lineoption.extendtype = 'dashline'
    else if (linetype == "dash" and bold == "Middle2Bold")
      lineoption.strokeDashArray = [10, 5]
      lineoption.extendtype = 'middle2bolddashline'
    else if (linetype == "dash" and bold == "MiddleBold")
      lineoption.strokeDashArray = [15, 5]
      lineoption.extendtype = 'middlebolddashline'
    else if (linetype == "dash" and bold == "Bold")
      lineoption.strokeDashArray = [20, 5]
      lineoption.extendtype = 'bolddashline'
    line = new fabric.Line(pos, lineoption)

    centerX = (line.x1 + line.x2) / 2
    centerY = (line.y1 + line.y2) / 2
    deltaX = line.left - centerX
    deltaY = line.top - centerY

    circle0 = new fabric.Circle(@merge(@circle_baseoption, {
      left: line.get('x1') + deltaX
      top: line.get('y1') + deltaY
      pointType: 'arrow_start',
      extendtype: extendtype
    }))
    circle0.line = line

    circle = new fabric.Circle(@merge(@circle_baseoption, {
      left: line.get('x2') + deltaX
      top: line.get('y2') + deltaY
      pointType: 'arrow_end'
      extendtype: extendtype
    }))
    circle.line = line

    if (text != "")
      textbox = new fabric.Text(text, @merge(@textbox_baseoption, {
        top: centerX,
        left: centerY,
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        extendtype: extendtype + '-text'
      }))

      textbox.line = line
      textbox.set({fill: '#fff'})

    if (text != "")
      line.customType = circle0.customType = circle.customType = 'arrow'
      line.circle = circle0.circle = textbox.circle = circle
      line.circle0 = circle.circle0 = textbox.circle0 = circle0
      line.textbox = circle.textbox = circle0.textbox = textbox
    else
      line.customType = circle0.customType = circle.customType = 'arrow'
      line.circle = circle0.circle = circle
      line.circle0 = circle.circle0 = circle0

    if (text != "")
      @canvas.add(line, circle0, circle, textbox)
    else
      @canvas.add(line, circle0, circle)

    @canvas.renderAll()

    _moveEnd3 = (obj) ->
      if (obj.pointType == 'arrow_end')
        obj.line.set('x2', obj.get('left'))
        obj.line.set('y2', obj.get('top'))
      else
        obj.line.set('x1', obj.get('left'))
        obj.line.set('y1', obj.get('top'))

      if (text != "")
        obj.line.textbox.set({
          'left': (line.x1 + line.x2) / 2,
          'top': (line.y1 + line.y2) / 2
        }).setCoords()

      obj.line._setWidthHeight()
      obj.canvas.eFabric.preventArrow(obj)
      obj.line.setCoords()
      obj.canvas.renderAll()

    _moveLine3 = (line) ->
      oldCenterX = (line.x1 + line.x2) / 2
      oldCenterY = (line.y1 + line.y2) / 2
      deltaX = line.left - oldCenterX
      deltaY = line.top - oldCenterY

      line.circle0.set({
        'left': line.x1 + deltaX,
        'top': line.y1 + deltaY
      }).setCoords()

      line.circle.set({
        'left': line.x2 + deltaX,
        'top': line.y2 + deltaY
      }).setCoords()

      if (text != "")
        line.textbox.set({
          'left': (line.x1 + line.x2) / 2,
          'top': (line.y1 + line.y2) / 2
        }).setCoords()

      line.set({
        'x1': line.x1 + deltaX,
        'y1': line.y1 + deltaY,
        'x2': line.x2 + deltaX,
        'y2': line.y2 + deltaY
      })

      line.set({
        'left': (line.x1 + line.x2) / 2,
        'top': (line.y1 + line.y2) / 2
      })
      line.canvas.eFabric.preventLineOut(line,false)

    _moveEnd3(circle)
    _moveEnd3(circle0)
    circle0.on({'moving': ->
      console.log("circle0 moving")
      _moveEnd3(circle0)
    })

    circle.on({'moving': ->
      console.log("circle moving")
      _moveEnd3(circle)
    })

    line.on({
      'moving': ->
        console.log("line moving")
        _moveLine3(line)
      # 'moved': (obj) ->
      #   console.log("line moved")
      #   canvas.discardActiveObject()
    })

  addBoldLine:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    @addLine(pos, text, color, "Bold")

  addMiddleBoldLine:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    @addLine(pos, text, color, "MiddleBold")

  addMiddle2BoldLine:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    @addLine(pos, text, color, "Middle2Bold")

  addDashLine:(pos = [0, 0, 100, 100], text = "", color = "red", options = {width: 1}) ->
    @addLine(pos, text, color, "normal", "dash", options.width)

  addMiddle2BoldDashLine:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    @addLine(pos, text, color, "Middle2Bold", "dash")

  addMiddleBoldDashLine:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    @addLine(pos, text, color, "MiddleBold", "dash")

  addBoldDashLine:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    @addLine(pos, text, color, "Bold", "dash")

  addArrow:(pos = [0, 0, 100, 100], text = "", color = "red", bold = "normal") ->
    console.log pos if debugmode? and debugmode == true and console?
    if (bold == "Bold")
      strokeWidth = 30
      extendtype = 'boldarrow'
      width = 60
      height = 20
    else if (bold == "MiddleBold")
      strokeWidth = 18
      extendtype = 'middleboldarrow'
      width = 40
      height = 15
    else if (bold == "Middle2Bold")
      strokeWidth = 9
      extendtype = 'middle2boldarrow'
      width = 20
      height = 10
    else
      strokeWidth = 1
      extendtype = 'singlearrow'
      width = 8
      height = 8
    line = new fabric.Line(pos, {
      stroke: color
      selectable: false
      strokeWidth: strokeWidth
      padding: 0
      hasBorders: false
      hasControls: false
      originX: 'center'
      originY: 'center'
      lockScalingX: true
      lockScalingY: true
      extendtype: extendtype
      ontext: text
      perPixelTargetFind: true
    })

    centerX = (line.x1 + line.x2) / 2
    centerY = (line.y1 + line.y2) / 2
    deltaX = line.left - centerX
    deltaY = line.top - centerY

    arrow = new fabric.Triangle({
      left: line.get('x1') + deltaX,
      top: line.get('y1') + deltaY,
      #stroke: 'black',
      #//stroke: 'blue',
      #strokeWidth: 1,
      originX: 'center',
      originY: 'center',
      selectable: true
      hasBorders: false,
      hasControls: false,
      lockScalingX: false,
      lockScalingY: false,
      lockRotation: true,
      pointType: 'arrow_start',
      angle: -45,
      width: width,
      height: height,
      fill: color,
      extendtype: extendtype
      perPixelTargetFind: true
    })
    arrow.line = line

    pointarrow = new fabric.Triangle({
      left: line.get('x1') + deltaX,
      top: line.get('y1') + deltaY,
      stroke: 'red',
      strokeWidth: 1,
      strokeDashArray: [2, 2]
      selectable: true
      opacity: 0.0
      originX: 'center',
      originY: 'center',
      hasBorders: false,
      hasControls: false,
      lockScalingX: false,
      lockScalingY: false,
      lockRotation: true,
      pointType: 'pointarrow_start',
      angle: -45,
      width: 80,
      height: 20,
      fill: '#ffffff',
      extendtype: extendtype
      perPixelTargetFind: true
    })
    pointarrow.line = line

    circle = new fabric.Circle({
      left: line.get('x2') + deltaX,
      top: line.get('y2') + deltaY,
      radius: 25,
      stroke: 'red',
      strokeWidth: 1,
      strokeDashArray: [2, 2]
      opacity: 0.0,
      originX: 'center',
      originY: 'center',
      hasBorders: false,
      hasControls: false,
      lockScalingX: true,
      lockScalingY: true,
      lockRotation: true,
      pointType: 'arrow_end',
      fill: '#ffffff',
      extendtype: extendtype
    })
    circle.line = line
    if (text != "")
      textbox = new fabric.Text(text, @merge(@textbox_baseoption, {
        top: centerX,
        left: centerY,
        backgroundColor: color,
        extendtype: extendtype + '-text'
      }))
      textbox.line = line
      textbox.set({fill: '#fff'})
      line.customType = arrow.customType = circle.customType = textbox.customType = 'arrow'
      line.circle = arrow.circle = textbox.circle = circle
      line.arrow = circle.arrow = textbox.arrow = arrow
      line.textbox = circle.textbox = arrow.textbox = textbox

    else

      line.customType = arrow.customType = circle.customType = pointarrow.customType = 'arrow'
      line.circle = arrow.circle = pointarrow.circle = circle
      line.arrow = circle.arrow = pointarrow.arrow = arrow
      line.pointarrow = circle.pointarrow = pointarrow
    if (text != "")
      @canvas.add(line, arrow, circle, textbox, pointarrow)
    else
      @canvas.add(line, arrow, pointarrow, circle)

    _moveEnd1 = (obj) ->
      if (obj.pointType == 'arrow_end')
        obj.line.set('x2', obj.get('left'))
        obj.line.set('y2', obj.get('top'))
      else
        obj.line.set('x1', obj.get('left'))
        obj.line.set('y1', obj.get('top'))
        console.log(obj.pointType)
        obj.line.arrow.set({
          'left': obj.get('left')
          'top': obj.get('top')
        })

      if (text != "")
        obj.line.textbox.set({
          'left': (line.x1 + line.x2) / 2,
          'top': (line.y1 + line.y2) / 2
        }).setCoords()

      obj.line._setWidthHeight()

      x1 = obj.line.get('x1')
      y1 = obj.line.get('y1')
      x2 = obj.line.get('x2')
      y2 = obj.line.get('y2')

      angle = obj.canvas.eFabric.calcArrowAngle(x1, y1, x2, y2)

      if (obj.pointType == 'arrow_end')
        obj.arrow.set('angle', angle - 90)
        obj.line.pointarrow.set('angle', angle - 90)
      else
        obj.set('angle', angle - 90)
        obj.line.arrow.set('angle', angle - 90)

      obj.canvas.eFabric.preventArrow(obj)
      obj.line.setCoords()
      obj.canvas.renderAll()

    _moveLine1 = (line) ->
      oldCenterX = (line.x1 + line.x2) / 2
      oldCenterY = (line.y1 + line.y2) / 2
      deltaX = line.left - oldCenterX
      deltaY = line.top - oldCenterY

      line.arrow.set({
        'left': line.x1 + deltaX,
        'top': line.y1 + deltaY
      }).setCoords()
      line.pointarrow.set({
        'left': line.x1 + deltaX,
        'top': line.y1 + deltaY
      }).setCoords()

      line.circle.set({
        'left': line.x2 + deltaX,
        'top': line.y2 + deltaY
      }).setCoords()

      if (text != "")
        line.textbox.set({
          'left': (line.x1 + line.x2) / 2,
          'top': (line.y1 + line.y2) / 2
        }).setCoords()

      line.set({
        'x1': line.x1 + deltaX,
        'y1': line.y1 + deltaY,
        'x2': line.x2 + deltaX,
        'y2': line.y2 + deltaY
      })

      line.set({
        'left': (line.x1 + line.x2) / 2,
        'top': (line.y1 + line.y2) / 2
      })
      line.canvas.eFabric.preventLineOut(line)

    _moveEnd1(arrow)
    _moveEnd1(pointarrow)
    _moveEnd1(circle)
    arrow.on({'moving': ->
      _moveEnd1(arrow)
    })
    pointarrow.on({'moving': ->
      #_moveEnd1(arrow)
      _moveEnd1(pointarrow)
    })
    circle.on({'moving': ->
      _moveEnd1(circle)
    })
    line.on({'moving': ->
      console.log 'moving' if debugmode? and debugmode == true and console?
      _moveLine1(line)
    })

  addBoldArrow:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    @addArrow(pos, text, color, "Bold")

  addMiddleBoldArrow:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    console.log "middleboldarrow" if debugmode? and debugmode == true and console?
    @addArrow(pos, text, color, "MiddleBold")

  addMiddle2BoldArrow:(pos = [0, 0, 100, 100], text = "", color = "red") ->
    console.log "middleboldarrow" if debugmode? and debugmode == true and console?
    @addArrow(pos, text, color, "Middle2Bold")

  addEllipse:(pos = [150, 150], text = "", color = "red") ->
    width = 50
    height = 30
    ellipse = new fabric.Ellipse({ #楕円
      left: pos[0] - (width ),
      top: pos[1] - (height),
      rx: width,
      ry: height,
      stroke: color,
      strokeWidth: 2,
      strokeUniform: true
      fill: 'transparent',
    })
    textbox = new fabric.Text(text, {
      width: 50,
      left: pos[0] - 7,
      top: pos[1] - 11,
      fontSize: 20,
      textAlign: 'center',
      selectable: false,
      backgroundColor: 'transparent',
    })
    textbox.set({
      fill: color,
    })
    ellipsetext = new fabric.Group([ellipse, textbox], {
      hasControls: true,
      cornerStyle: 'circle',
      cornerSize: 25,
      cornerColor: 'rgba(178, 204, 255, 0.1)'
      cornerStrokeColor: 'rgba(178, 204, 255, 0.8)'
      borderColor: 'rgba(178, 204, 255, 0.8)'
      hasRotatingPoint: true
    })
    ellipsetext.setControlsVisibility({
      tl: false,
      tr: false,
      br: false,
      bl: true,
      ml: true
      mt: false,
      mr: false,
      mb: true, #下
      mtr: true,
    })
    @canvas.add(ellipsetext)

  addCircleText:(pos = [150, 150], text = "", color = "red") ->
    text = @partnextnum
    console.log pos if debugmode? and debugmode == true and console?
    circle = new fabric.Circle({
      left: pos[0],
      top: pos[1],
      radius: 20,
      #stroke: 'transparent',
      stroke: color,
      strokeWidth: 1,
      originX: 'center',
      originY: 'center',
      hasBorders: false,
      hasControls: true,
      lockScalingX: false,
      lockScalingY: false,
      lockRotation: false,
      fill: 'transparent',
      extendtype: 'circletext'
    })
    textbox = new fabric.Text(text, {
      width: 50,
      left: pos[0] - 7,
      top: pos[1] - 11,
      fontSize: 20,
      textAlign: 'center',
      selectable: false,
      backgroundColor: 'transparent',
    })
    textbox.set({
      fill: color,
    })
    circletext = new fabric.Group([circle, textbox], {
      hasControls: true,
      cornerStyle: 'circle',
      cornerSize: 25,
    })
    circletext.setControlsVisibility({
      tl: false,
      tr: false,
      br: false,
      bl: false,
      ml: false,
      mt: false,
      mr: false,
      mb: false,
      mtr: false,
    })
    @canvas.add(circletext)

  addCross:(pos, text = "2", color = "red") ->
    text = String(@partnextnum)
    part_id = @part_id
    console.log pos if debugmode? and debugmode == true and console?
    size = 5
    line1 = new fabric.Line([(pos[0] - size), (pos[1] - size), (pos[0] + size), (pos[1] + size)], {
      stroke: color,
      selectable: true,
      strokeWidth: 1,
      padding: 5,
      hasBorders: true,
      hasControls: false,
      originX: 'center',
      originY: 'center',
      lockScalingX: true,
      lockScalingY: true,
      angle: 90,
    })
    line2 = new fabric.Line([(pos[0] - size), (pos[1] - size), (pos[0] + size), (pos[1] + size)], {
      stroke: color,
      selectable: true,
      strokeWidth: 1,
      padding: 5,
      hasBorders: true,
      hasControls: false,
      originX: 'center',
      originY: 'center',
      lockScalingX: true,
      lockScalingY: true,
      angle: 180,
    })
    circle = new fabric.Circle({
      left: pos[0] + size - 3,
      top: pos[1],
      radius: 5,
      stroke: color,
      strokeWidth: 1,
      originX: 'center',
      originY: 'center',
      hasBorders: false,
      hasControls: false,
      lockScalingX: true,
      lockScalingY: true,
      lockRotation: true,
      fill: 'transparent'
    })
    # if text.length == 2
    #   left = pos[0] - 7 + size + 4
    # if text.length == 1
    #   left = pos[0] - 7 + size + 8
    textbox = new fabric.Textbox(text, {
      width: 10,
      left: pos[0] - 3,
      top: pos[1] - 5,
      fontSize: 8,
      textAlign: 'center',
      selectable: false,
      backgroundColor: 'transparent',
      #backgroundColor: '#ffffff',
    })
    textbox.set({fill: color})
    # 破損箇所idを取得してプロパティに含める
    #cross = new fabric.Group([line1, line2, circle, textbox], {
    cross = new fabric.Group([circle, textbox], {
      hasBorder: false
      hasControls: true
      extendtype: 'cross'
      part_id: part_id
    })
    @crossoption(cross)
    @canvas.add(cross)

  crossoption: (obj) ->
    obj.setControlsVisibility({
      tl: false
      tr: false
      br: false
      bl: false
      ml: false
      mt: false
      mr: false
      mb: false
      mtr: false
    })
    # obj.controls.delete = new fabric.Control({
    #   x: -0.7
    #   y: -0.7
    #   offsetY: 0
    #   cursorStyle: 'pointer'
    #   mouseUpHandler: @deleteObject
    #   render: @renderDeleteIcon
    #   cornerSize: 20
    # })

  addText: (pos = [150, 150], text = "テスト", color = 'red') ->
    textbox = new fabric.Textbox(text,
    @merge(@baseoption,
    {
      left: pos[0]
      top: pos[1]
      fontSize: 25
      textAlign: 'center'
      selectable: true
      backgroundColor: 'transparent'
      editable: false
      fill: color
      #backgroundColor: '#ffffff',
    }))
    @canvas.add(textbox)
    @canvas.setActiveObject(textbox)

  deleteObject: (eventData, target) ->
    if (confirm('削除しますか？'))
      canvas = target.canvas
      canvas.remove(target)
      canvas.requestRenderAll()

  renderDeleteIcon: (ctx, left, top, styleOverride, fabricObject) ->
    img = document.createElement('img')
    img.src = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E"
    size = this.cornerSize
    ctx.save()
    ctx.translate(left, top)
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
    ctx.drawImage(img, -size / 2, -size / 2, size, size)
    ctx.restore()

  setObject: (obj) ->
    @obj = obj

  addNormalLine: (pos = [0, 0, 100, 100], color = 'red') ->
    line = new fabric.Line(pos, {
      stroke: color
      selectable: true
      strokeWidth: 1
      padding: 5
      hasBorders: true
      hasControls: true
      originX: 'center'
      originY: 'center'
      lockScalingX: true
      lockScalingY: true
      perPixelTargetFind: true
    })
    @canvas.add(line)

  preventArrow: (obj) ->
    boundingRect = obj.getBoundingRect(true)
    x1 = obj.line.get('x1')
    y1 = obj.line.get('y1')
    x2 = obj.line.get('x2')
    y2 = obj.line.get('y2')
    if (y1 < 0 )
      obj.set('top', 0)
      obj.line.set('y1', 0)
    if (y1 > @canvas.getHeight())
      obj.set('top', @canvas.getHeight())
      obj.line.set('y1', @canvas.getHeight())
    if (x1 < 0 )
      obj.set('left', 0)
      obj.line.set('x1', 0)
    if (x1 > @canvas.getWidth())
      obj.set('left', @canvas.getWidth())
      obj.line.set('x1', @canvas.getWidth())
    if (y2 < 0 )
      obj.set('top', 0)
      obj.line.set('y2', 0)
    if (y2 > @canvas.getHeight())
      obj.set('top', @canvas.getHeight())
      obj.line.set('y2', @canvas.getHeight())
    if (x2 < 0 )
      obj.set('left', 0)
      obj.line.set('x2', 0)
    if (x2 > @canvas.getWidth())
      obj.set('left', @canvas.getWidth())
      obj.line.set('x2', @canvas.getWidth())

  preventLineOut: (line) ->
    x1 = line.x1
    y1 = line.y1
    x2 = line.x2
    y2 = line.y2
    if (y1 < 0 )
      line.set('y1', 0)
    if (y1 > @canvas.getHeight())
      line.set('y1', @canvas.getHeight())
    if (x1 < 0 )
      line.set('x1', 0)
    if (x1 > @canvas.getWidth())
      line.set('x1', @canvas.getWidth())
    if (y2 < 0 )
      line.set('y2', 0)
    if (y2 > @canvas.getHeight())
      line.set('y2', @canvas.getHeight())
    if (x2 < 0 )
      line.set('x2', 0)
    if (x2 > @canvas.getWidth())
      line.set('x2', @canvas.getWidth())

    angle = line.canvas.eFabric.calcArrowAngle(x1, y1, x2, y2)
    if (line.arrow?)
      line.arrow.set('angle', angle - 90)

    if(line.arrow2?)
      line.arrow2.set('angle', angle + 90)

  calcArrowAngle: (x1, y1, x2, y2) ->
    angle = 0
    x = null
    y = null

    x = (x2 - x1)
    y = (y2 - y1)

    if (y1 == y2)
      if (x2 > x1)
        return 0
      else
        return 180
    else if (x1 == x2)
      if (y2 > y1)
        return 90
      else
        return 270

    if (x == 0)
      angle = (y == 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2
    else if (y == 0)
      angle = (x > 0) ? 0 : Math.PI
    else
      if (x < 0)
        angle = Math.atan(y / x) + Math.PI
      else if (y < 0)
        angle = Math.atan(y / x) + (2 * Math.PI)
      else
        angle = Math.atan(y / x)

    return (angle * 180 / Math.PI)

  merge: (xs...) ->
    if xs?.length > 0
      @tap {}, (m) -> m[k] = v for k, v of x for x in xs

  tap: (o, fn) -> fn(o); o

  activateImage: ->
    # $('#imageeditmenu').show()
    # $('#imageeditstartmenu').hide()
    # $('#savemenu').hide()
    if document.getElementById('imageeditmenu') != null
      document.getElementById('imageeditmenu').style.display = 'block'
    if document.getElementById('imageeditstartmenu') != null
      document.getElementById('imageeditstartmenu').style.display = 'none'
    if document.getElementById('savemenu') != null
      document.getElementById('savemenu').style.display = 'none'
    @canvas.forEachObject (obj) ->
      if obj.objectid and obj.objectid == 'mainimage'
        obj.selectable = true
        obj.lockRotation = true
        obj.
        console.log obj if debugmode? and debugmode == true and console?
        obj.canvas.setActiveObject obj
        return
      return
    return

  deactivateImage: ->
    obj = @canvas.getActiveObject()
    if typeof obj.type != null and obj.type == 'image'
      obj.sendToBack()
      obj.selectable = false
      @canvas.discardActiveObject()
    # $('#imageeditmenu').hide()
    # $('#imageeditstartmenu').show()
    # $('#savemenu').show()
    if document.getElementById('imageeditmenu') != null
      document.getElementById('imageeditmenu').style.display = 'none'
    if document.getElementById('imageeditstartmenu') != null
      document.getElementById('imageeditstartmenu').style.display = 'inline'
    if document.getElementById('savemenu') != null
      document.getElementById('savemenu').style.display = 'display'

  rangechange: (val) ->
    console.log(val)
    img = @canvas.getActiveObject()
    if img.width >= img.height
      img.scaleToHeight(@canvas.height * val / 100)
      # img.top = (@canvas.height - (@canvas.height *val / 100)) / 2
      # img.left = -1 * (img.width * img.scaleX - (@canvas.width)) / 2
      #console.log(img.left);
    else
      img.scaleToWidth(@canvas.width * val / 100)
      # img.top = -1 * (img.height * img.scaleY - (@canvas.height)) / 2
      # img.left = (@canvas.width - (@canvas.width * val / 100)) / 2
    @canvas.renderAll()
    return

  rotateright: ->
    img = @canvas.getActiveObject()
    width = img.width * img.scaleX
    height = img.height * img.scaleY
    if img.angle == 0
      console.log img.angle
      img.left = height
      img.top = 0
      img.angle += 90
    else if img.angle == 90
      console.log img.angle
      img.left = width
      img.top = height
      img.angle += 90
    else if img.angle == 180
      console.log img.angle
      img.left = 0
      img.top = width
      img.angle += 90
    else if img.angle == 270
      console.log img.angle
      img.left = 0
      img.top = 0
      img.angle = 0
    # if (img.angle == 360)
    #   img.angle = 0
    @canvas.renderAll()

  rotateleft: ->
    img = @canvas.getActiveObject()
    width = img.width * img.scaleX
    height = img.height * img.scaleY
    if img.angle == 0
      console.log img.angle
      img.left = 0
      img.top = width
      img.angle = 270
    else if img.angle == 270
      console.log img.angle
      img.left = width
      img.top = height
      img.angle = 180
    else if img.angle == 180
      console.log img.angle
      img.left = height
      img.top = 0
      img.angle = 90
    else if img.angle == 90
      console.log img.angle
      img.left = 0
      img.top = 0
      img.angle = 0
    # if (img.angle == 360)
    #   img.angle = 0
    @canvas.renderAll()

  stringifyCanvas: ->
    @canvas.discardActiveObject()
    canvas = @canvas
    #array of the attributes not saved by default that I want to save
    additionalFields = [
      'pointType'
      'selectable'
      'hasControls'
      'extendtype'
      'ontext'
      'objectid'
      'hasBorders'
      'cornerSize'
      'cornerColor'
      'cornerStrokeColor'
      'cornerStyle'
      'padding'
      'transparentCorners'
      'hasRotatingPoint'
      'rotatingPointOffset'
      '_controlsVisibility'
      'x1'
      'x2'
      'y1'
      'y2'
      'part_id'
      'editable'
      'strokeWidth'
      'strokeUniform'
      'strokeDashArray'
    ]
    extra = 'objects': []
    nCanvas = 'objects': []
    #console.log @canvas if debugmode? and debugmode == true and console?
    #console.log JSON.stringify(@canvas) if debugmode? and debugmode == true and console?
    sCanvas = JSON.stringify(@canvas)
    oCanvas = JSON.parse(sCanvas)
    d = undefined
    for n, object of oCanvas.objects
      #console.log n if debugmode? and debugmode == true and console?
      #console.log object if debugmode? and debugmode == true and console?
      # 自前のものなどは、始点・終点・テキストだけを保存し、再度addArrowなどすればよい。
      if (
        canvas.item? and
        _.contains([
          'singlearrow'
          'doublearrow'
          'line'
          'measureline'
          'boldarrow'
          'middleboldarrow'
          'bolddoublearrow'
          'middlebolddoublearrow'
          'middle2boldarrow'
          'middle2bolddoublearrow'
          'dashline'
          'middle2bolddashline'
          'middlebolddashline'
          'bolddashline'
        ], canvas.item(n)['extendtype'])
        # (
        #   RegExp('^singlearrow').test(canvas.item(n)['extendtype']) or
        #   RegExp('^doublearrow').test(canvas.item(n)['extendtype']) or
        #   RegExp('^line').test(canvas.item(n)['extendtype']) or
        #   RegExp('^measureline').test(canvas.item(n)['extendtype']) or
        #   RegExp('^bolddoublearrow').test(canvas.item(n)['extendtype']) or
        #   RegExp('^doublemiddleboldarrow').test(canvas.item(n)['extendtype']) or
        #   RegExp('^boldarrow').test(canvas.item(n)['extendtype']) or
        #   RegExp('^middleboldarrow').test(canvas.item(n)['extendtype']) or
        #   RegExp('^middle2boldarrow').test(canvas.item(n)['extendtype']) or
        #   RegExp('^middlebolddoublearrow').test(canvas.item(n)['extendtype']) or
        #   RegExp('^middle2bolddoublearrow').test(canvas.item(n)['extendtype'])
        # )
      )
        console.log canvas.item(n)['extendtype'] if debugmode? and debugmode == true and console?
        #矢印はlineだけを保存し他は無視。始点・終点・カラー・テキストが保存してあればいい
        if canvas.item(n)['type'] == 'line'
          d = oCanvas.objects[n]
          for m, field of additionalFields
            d[field] = canvas.item(n)[field]
          extra.objects.push d
      else if canvas.item? and (canvas.item(n)['extendtype'] == 'compass')
        # コンパスは位置、angleが保存してあればいい
        d = oCanvas.objects[n]
        for m, field of additionalFields
          d[field] = canvas.item(n)[field]
        extra.objects.push d
      else
        d = oCanvas.objects[n]
        for m, field of additionalFields
          #console.log m if debugmode? and debugmode == true and console?
          d[field] = canvas.item(n)[field]
        nCanvas.objects.push d
    #console.log extra if debugmode? and debugmode == true and console?
    nCanvas.extra = extra
    nCanvas.canvas = [width: canvas.width, height: canvas.height, photo_id: @canvas.photo_id]
    #console.log nCanvas if debugmode? and debugmode == true and console?
    return JSON.stringify nCanvas


  restore: (data, extra) ->
    # if data? and data.extra?
    #   delete data.extra
    canvas = @canvas
    efab = @
    console.log data if debugmode? and debugmode == true and console?
    console.log extra if debugmode? and debugmode == true and console?

    @canvas.loadFromJSON data, ->
      canvas.renderAll()
      #console.log data if debugmode? and debugmode == true and console?
      canvas.forEachObject (obj) ->
        if obj.extendtype == "cross"
          efab.crossoption(obj)

      if extra? and extra.objects?
        #console.log extra.objects
        for key, obj of extra.objects
          #console.log key if debugmode? and debugmode == true and console?
          switch obj.extendtype
            when "singlearrow"
              efab.addArrow([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "doublearrow"
              efab.addDoubleArrow([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "measureline"
              efab.addMeasureLine([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "boldarrow"
              efab.addBoldArrow([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "middleboldarrow"
              efab.addMiddleBoldArrow([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "middle2boldarrow"
              efab.addMiddle2BoldArrow([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "bolddoublearrow"
              efab.addBoldDoubleArrow([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "middlebolddoublearrow"
              efab.addMiddleBoldDoubleArrow([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "middle2bolddoublearrow"
              efab.addMiddle2BoldDoubleArrow([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "line"
              console.log(obj)
              efab.addLine([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "boldline"
              console.log(obj)
              efab.addBoldLine([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "middleboldline"
              console.log(obj)
              efab.addMiddleBoldLine([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "middle2boldline"
              console.log(obj)
              efab.addMiddle2BoldLine([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "dashline"
              console.log(obj)
              efab.addDashLine([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "middle2bolddashline"
              console.log(obj)
              efab.addMiddle2BoldDashLine([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "middlebolddashline"
              console.log(obj)
              efab.addMiddleBoldDashLine([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "bolddashline"
              console.log(obj)
              efab.addBoldDashLine([obj.x1, obj.y1, obj.x2, obj.y2], obj.ontext, obj.stroke)
            when "compass"
              efab.addCompass(obj.top, obj.left, obj.width, obj.height, obj.angle)
            # addArrowToCanvas([obj.left + obj.x1, obj.top + obj.y1, obj.left + obj.width + obj.x1, obj.top + obj.height + obj.y1], obj.ontext, obj.stroke)
            else
              console.log "該当なし", obj if debugmode? and debugmode == true and console?
      efab.bringToFrontText()

      canvas.renderAll()
      console.log "保存されたjsonから復元しました。" if debugmode? and debugmode == true and console?
      return


  bringToFrontText: ->
    @canvas.forEachObject (obj) -> # textboxだけは上に持ってくる
      #console.log(obj.type)
      if obj.type == "textbox"
        obj.canvas.bringToFront(obj)

  restoretest: ->
    json = @stringifyCanvas()
    #console.log json if debugmode? and debugmode == true and console?
    x = JSON.parse(json)
    #console.log x if debugmode? and debugmode == true and console?
    @canvas.clear()
    #@canvas.loadFromJSON(json, @canvas.renderAll.bind(@canvas))
    @restore(x, x.extra)

  toDataURL: (opt = {format: 'jpeg', multiplier: 4}) ->
    @canvas.toDataURL(opt)
  dump: ->
    #console.log @canvas.toJSON()
    console.log @stringifyCanvas() if debugmode? and debugmode == true and console?
    return

  delete: ->
    objects = @canvas.getActiveObjects()
    if objects?
      objects.forEach (obj) =>
        if obj.arrow?
          @canvas.remove(obj.arrow)
        if obj.arrow2?
          @canvas.remove(obj.arrow2)
        if obj.line?
          @canvas.remove(obj.line)
        if obj.circle?
          @canvas.remove(obj.circle)
        if obj.circle0?
          @canvas.remove(obj.circle0)
        if obj.triangle?
          @canvas.remove(obj.triangle)
        if obj.text?
          @canvas.remove(obj.text)
        if obj.textbox?
          @canvas.remove(obj.textbox)
        @canvas.remove(obj)
        console.log obj if debugmode? and debugmode == true and console?
        @canvas.renderAll()
      @onselectchange(false)


eFabric = new Efabric

# Local Variables:
# mode: coffee
# coding: utf-8
# End:
